<template>
    <div class="login_page">
    <div class="left_part"></div>
    <div class="right_part">
      <div class="logo_div">
        <img src="../assets/logo.png" />
      </div>
    </div>
    <div class="black-overlay">
      <div class="box-modal smallPops">
        <div class="body">
            <div class="pop_container">
                <div class="label_and_element_wrapper">
                    <label>Enter the OTP sent to your registered email address <span>*</span></label>
                    <input type="number" v-model="otp" placeholder="Enter the 6 digit OTP" min="100000" max="999999" @keyup.enter="$refs.otp_submit.click()"/>
                </div>
                <div class="label_and_element_wrapper">
                    <button @click="verifyOtp" ref="otp_submit">Submit</button>
                </div>
                <span @click="resendOtp">Resend OTP</span>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name:"OtpVerify",
    data(){
      return {
        otp:""
      }
    },
    created(){
      try{
        let user = this.auth();
        if(user.is_authenticated != 0){
          this.axios.post("api/auth/logout");
          throw "Unauthorized access";
        }
      }catch(e){
        localStorage.clear();
        this.toast.error("You must login first");
        this.$router.push("/");
      }
    },
    methods:{
      verifyOtp(){
        this.axios.post("api/auth/verifyotp",{
                    otp:this.otp
                  })
                  .then(response => {
                    localStorage.token = response.data.token;
                    this.toast.success("Logged in successfully")
                    this.$router.replace("/pages/dashboard");
                  })
                  .catch(error => {
                    console.log(error);
                  });
      },
      resendOtp(){
        this.axios.post("api/auth/resendotp")
                  .then(response => {
                    this.toast.success(response.data.msg);
                  })
                  .catch(error => {
                    console.log(error);
                  });
      }
    }
}
</script>

<style scoped>
.login_page {
  display: flex;
  width: 100%;
  height: 100%;
  position: fixed;
}
.left_part {
  width: 100%;
  height: 100%;
  background: #1b1b1b url(../assets/login-bg.jpg) no-repeat;
  background-size: contain;
  min-height: 200px;
  background-position: center;
}
.right_part {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.right_part .logo_div {
  width: 175px;
  height: 90px;
  margin-bottom: 30px;
}
.right_part .logo_div img {
  display: block;
  width: 100%;
  height: auto;
}
.pop_container{
    height:auto;
}
.pop_container span{
  padding:5px 10px;
  float:right;
  cursor:pointer;
  text-decoration: underline;
  font-size:15px;
}

button {
  width: 100%;
  height: 50px;
  background: #06A5ED;
  color: #000;
  font-size: 16px;
  padding: 5px 30px;
  border: 0;
}
.smallPops{
    display:flex;
    align-items: center;
    min-height:220px !important;
    padding:10px;
    width:320px !important;
}
</style>